.btn {
  @include appearance-none;
  background: $orange;
  border: 2px solid $cream;
  box-shadow: 4px 4px 0 $cream;
  color: $cream;
  display: inline-block;
  font-family: 'IBM Plex Mono', monospace;
  font-size: 14px;
  padding: 8px 20px;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    background-color: $orange_dark; }

  &.wide {
    padding: 6px 50px; }

  &.arrow {
    &:after {
      content: "";
      background: url(../img/icon-arrow.svg) no-repeat center center;
      display: inline-block;
      height: 12px;
      margin-left: 8px;
      width: 12px; } }

  &.envelope {
    &:after {
      content: "";
      background: url(../img/icon-envelope.svg) no-repeat center center;
      display: inline-block;
      height: 11px;
      margin-left: 8px;
      width: 17px; } }

  &.primary {
    background: $teal;
    border: 2px solid $teal;
    box-shadow: 4px 4px 0 $cream, 6px 6px 0 $teal;
    font-weight: 600;
    &:hover {
      background-color: darken($teal, 15%); } }

  &.outline {
    background: $cream;
    border: 2px solid $teal;
    color: $teal;
    box-shadow: 4px 4px 0 $teal;
    font-weight: 600;
    &:hover {
      background-color: rgba($teal_light, 0.25); }

    &.arrow {
      &:after {
        background-image: url(../img/icon-arrow-teal.svg); } } } }
