header#header {
  @include box-sizing;
  background: $orange;
  font-size: 16px;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 10;



  a {
    text-decoration: none;
    &:hover {
      color: $cream; } }

  #search-container {
    position: relative;
    input {
      @include box-sizing;
      background: rgba(#f9f5e3, 0.2) url(../img/icon-search.svg) no-repeat 10px center;
      border: 2px solid $black;
      display: block;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      padding: 12px 12px 12px 40px;
      width: 100%;
      &:focus {
        background-color: $cream;
        outline: 0 none;
        border-color: $teal; } }

    ::-webkit-input-placeholder {
      color: $black;
      text-transform: uppercase;
      font-size: 13px; }

    ::-moz-placeholder {
      color: $black;
      text-transform: uppercase;
      font-size: 13px; }

    -ms-input-placeholder: {
      color: $black;
      text-transform: uppercase;
      font-size: 13px; }

    -moz-placeholder: {
      color: $black;
      text-transform: uppercase;
      font-size: 13px; }

    #results-container {
      &:empty {
        display: none; }
      @include box-sizing;
      background-color: $teal_light;
      border: 2px solid $teal;
      border-width: 0 2px 2px;
      margin: 0;
      position: absolute;
      width: 100%;

      div { // no results
        padding: 10px; }
      a {
        display: block;
        padding: 10px 20px;
        font-weight: 600;
        font-size: 15px;
        &:hover {
          background: $teal; } }
      li {
        &:first-child {
          a {
            padding-top: 20px; } }
        &:last-child {
          a {
            padding-bottom: 20px; } } } } }


  .social {
    font-size: 20px; }


  @media (min-width: 768px) {
    background: $orange url(../img/pixels.svg) no-repeat left bottom;
    box-shadow: 0 0 10px rgba(black, 0.25);
    height: 100%;
    padding: 50px 25px 25px;
    width: 300px;

    .lines-button {
      display: none; }

    a#logo {
      margin-bottom: 58px; }

    #search-container {
      display: block;
      text-align: left;
      margin-bottom: 50px; }

    nav {
      text-align: left;
      margin-left: -25px;
      width: calc(100% + 50px);
      li {
        margin-bottom: 10px;
        a {
          font-weight: 600;
          padding: 15px 25px 15px 50px;
          display: inline-block; }
        &.active {
          background: $orange_dark;
          color: $cream; } } }

    .social {
      text-align: left;
      padding-left: 25px;
      a {
        margin-right: 10px; } } }

  a#logo {
    display: inline-block;

    &:hover {
      .icon {
        background-position: center -100px; }
      strong {
        opacity: 0.75; } }

    .icon {
      background: url(../img/sip-of-code-icon.svg) no-repeat center 0;
      height: 71px;
      width: 66px;
      margin: 0 auto 16px; }

    strong {
      display: block;
      margin: 0 auto;
      background: url(../img/sip-of-code.svg) no-repeat center center;
      height: 35px;
      width: 190px;
      text-indent: -30000px; } }

  ul {
    list-style: none;
    padding: 0; }

  @media (min-width: 768px) and (max-height: 650px) {
    padding-top: 35px;

    a#logo {
      margin-bottom: 35px; }

    nav {
      li {
        margin-bottom: 20px; } }

    #search-container {
      margin-bottom: 35px; } }


  @media (max-width: 767px) {
    @include box-sizing;
    padding: 10px;
    text-align: left;

    a#logo {
      .icon, strong {
        margin: 0;
        display: inline-block;
        vertical-align: middle; }

      .icon {
        margin-right: 10px; } }


    #search-container {
      display: none; }


    .lines-button {
      position: absolute;
      right: 15px;
      top: 27px; }
    nav {
      display: none;
      text-align: center;
      a {
        display: block;
        font-size: 22px;
        padding: 10px; } }
    .social {
      font-size: 24px;
      display: none;
      text-align: center;
      a {
        margin: 10px 5px; } } }


  &.nav-is-open {
    background: $orange url(../img/pixels.svg) no-repeat left bottom;
    height: 100%;
    nav, .social {
      display: block; } } }
