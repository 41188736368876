@mixin appearance-none {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

@mixin animation2($time) {
  -webkit-animation: wave $time linear infinite;
  -moz-animation: wave $time linear infinite;
  -o-animation: wave $time linear infinite;
  -animation: wave $time linear infinite; }

@mixin flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

@mixin animation($animation...) {
  -webkit-animation: $animation;
  -moz-animation: $animation;
  animation: $animation; }

@mixin animation-delay($animation-delay) {
  -moz-animation-delay: $animation-delay;
  -webkit-animation-delay: $animation-delay;
  animation-delay: $animation-delay; }



@mixin transition($transition...) {
  @if length($transition) == 0 {
    $transition: all 0.2s ease; }
  transition: $transition;
  -moz-transition: $transition;
  -webkit-transition: $transition;
  -o-transition: $transition; }

@mixin transition-delay($delay) {
  transition-delay: $delay;
  -webkit-transition-delay: $delay; }

@mixin font-smoothing {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

// Yeah Mr. White! EASINGS!
$easeInOutBack: cubic-bezier(0.680, -0.550, 0.265, 1.550);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.150, 0.860);
$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
// See more: http://matthewlein.com/ceaser/

@mixin inline-block {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto; }


@mixin gradient($from : black, $to : white) {
  background-color: $from;
  background-image: -moz-linear-gradient(top, $from, $to);
  background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, $from),color-stop(1, $to));
  background-image: linear-gradient(to bottom, $from, $to); }

@mixin gradient-horizontal($from, $to) {
  background: $from;
  background: -moz-linear-gradient(left, $from 0%, $to 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%,$from), color-stop(100%,$to)); }


@mixin rotate($value) {
  transform: rotate($value+deg);
  -ms-transform: rotate($value+deg);
  -webkit-transform: rotate($value+deg); }

@mixin calc($property, $expression) {
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: -moz-calc(#{$expression});
  #{$property}: calc(#{$expression}); }

@mixin transform($rule) {
  -webkit-transform: $rule;
  -moz-transform: $rule;
  -o-transform: $rule;
  transform: $rule; }

@mixin vertical-align {
  top: 50%;
  @include transform(translateY(-50%)); }

@mixin reset-vertical-align {
  @include transform(none);
  top: 0; }

@mixin horizontal-align {
  left: 50%;
  @include transform(translateX(-50%)); }

@mixin box-sizing($value : border-box) {
  box-sizing: $value;
  -moz-box-sizing: $value; }

@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

@mixin clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table; }
  &:after {
    clear: both; } }

@mixin checkmark {
  font-family: 'fontAwesome';
  content: "\f00c"; }

@mixin caret-down {
  font-family: 'fontAwesome';
  content: "\f0d7";
  font-weight: normal; }

@mixin caret-up {
  font-family: 'fontAwesome';
  content: "\f0d8";
  font-weight: normal; }

@mixin caret-right {
  font-family: 'fontAwesome';
  content: "\f0da";
  font-weight: normal; }

@mixin caret-left {
  font-family: 'fontAwesome';
  content: "\f0d9";
  font-weight: normal; }

@mixin angle-right {
  font-family: 'fontAwesome';
  content: "\f105";
  font-weight: normal; }

@mixin angle-left {
  font-family: 'fontAwesome';
  content: "\f104";
  font-weight: normal; }

@mixin angle-down {
  font-family: 'fontAwesome';
  content: "\f107";
  font-weight: normal; }

@mixin angle-up {
  font-family: 'fontAwesome';
  content: "\f106";
  font-weight: normal; }

@mixin chevron-right {
  font-family: 'fontAwesome';
  content: "\f054";
  font-weight: normal; }

@mixin chevron-left {
  font-family: 'fontAwesome';
  content: "\f053";
  font-weight: normal; }

@mixin chevron-down {
  font-family: 'fontAwesome';
  content: "\f078";
  font-weight: normal; }

@mixin chevron-up {
  font-family: 'fontAwesome';
  content: "\f077";
  font-weight: normal; }

@mixin anti-shifting {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1.0, 1.0);
  transform: translateZ(0); }


