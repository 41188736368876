#notice {
  @include box-sizing;
  background-color: $teal;
  font-size: 16px;
  font-weight: 600;
  left: 0;
  padding: 10px 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 11;

  img {
    margin-right: 10px; }

  a.btn {
    margin-left: 10px; }

  .wrapper {
    margin: 0 auto;
    > * {
      display: inline-block;
      vertical-align: middle; } }


  + header#header {
    margin-top: 51px;
    @media (min-width: 768px) {
      height: calc(100vh - 51px); }

    + .wrapper {
      padding-top: 145px; } }


  @media (min-width: 1180px) {
    background: url(../img/pixels2.svg) no-repeat 10% bottom, url(../img/pixels3.svg) no-repeat 90% bottom;
    background-color: $teal; }

  @media (max-width: 767px) {
    text-align: left;
    font-size: 13px;
    min-height: 66px;
    img {
      float: left;
      margin-right: 20px; }

    a.btn {
      font-size: 10px;
      padding: 3px 10px;
      box-shadow: 2px 2px 0 $cream; }

    + header#header {
      margin-top: 66px;

      + .wrapper {
        padding-top: 150px; } } } }
