@import "colors.sass";
@import "mixins.sass";

// Vendor
@import "animated-hamburguer-icon.sass";
@import "buttons.sass";


@import "header.sass";
@import "notice.sass";
@import "about.sass";
@import "projects.sass";
@import "article.sass";


body {
  background: $cream;
  color: $black;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2em;

  &.avoid-scrolling {
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
    height: 100vh; }

  @media (min-width: 768px) {
    padding-left: 300px; }

  @media (min-width: 1350px) {
    padding-left: 340px; }

  .wrapper {
    padding: 0 15px;
    max-width: 980px; } }

a {
  color: inherit; }

h1 {
  font-size: 34px;
  @media (max-width: 767px) {
    font-size: 30px; } }

p {
  font-size: 16px;
  margin-top: 20px; }

section {
  p, ul li {
    strong {
      color: $orange; }
    strong, a {
      @include font-smoothing;
      font-family: 'IBM Plex Mono', monospace;
      font-size: 110%;
      font-weight: 700; }
    a {
      color: $teal;
      text-decoration: none;
      &:hover {
        text-decoration: underline; } } } }


section.wrapper {
  padding-top: 96px;
  padding-bottom: 25px; }


// LEGACY
.container {
  padding: 0.5em; }


footer {
  border-top: 1px solid black;
  padding: 1em 0;
  margin-bottom: 1em; }


blockquote {
  border-left: 0.25em solid #dbd48a;
  padding: 0 1em;
  font-style: oblique;
  margin: auto 0; }

div.highlight {
  overflow-x: scroll; }

aside li {
  margin: 0.5em 0; }

.video-container {
  position: relative;
  padding-top: 30px;
  padding-bottom: 50%;
  overflow: hidden;

  iframe, object, embed, .video-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

  img.play {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 10%; }

  img {
    cursor: pointer; } }


// Work with me
.mailbox {
  text-align: center;
  padding-top: 20px;
  .btn {
    position: relative; }
  img {
    @include transform(translateX(15px));
    display: block;
    margin: 0 auto -2px; } }


h2 {
  font-size: 20px;
  font-weight: 600; }

.when {
  color: $gray_medium;
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 2px;
  i {
    margin-right: 3px; } }

ul.posts {
  list-style: none;
  padding: 0;

  li {
    position: relative; }

  .flex {
    @media (min-width: 940px) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end; }

    h2 {
      margin: 0;
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline; } } }



    @media (max-width: 939px) {
      h2 {
        margin-bottom: 5px; } } }


  p {
    font-weight: 400;
    font-size: 15px; }



  hr {
    background: $gray_line;
    border: 0 none;
    height: 1px;
    margin: 40px 0 30px;
    &:before {
      content: "";
      height: 45px;
      margin-top: -22px;
      position: absolute;
      width: 45px; } }

  li {
    &:nth-child(7n+1) {
      hr {
        &:before {
          background: url(../img/hr1.svg) no-repeat center center;
          left: 70%; } } }

    &:nth-child(7n+3) {
      hr {
        &:before {
          background: url(../img/hr2.svg) no-repeat center center;
          left: 30%; } } }

    &:nth-child(7n+5) {
      hr {
        &:before {
          background: url(../img/hr3.svg) no-repeat center center;
          height: 45px;
          left: 50%; } } } } }



.pagination {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  a {
    background: $teal;
    color: $cream;
    display: inline-block;
    font-weight: bold;
    padding: 2px 7px;
    text-decoration: none;
    &:hover {
      background: $teal_light; } } }


