.highlight {
  pre {
    padding: 1em;
    margin: 0; }
  .hll {
    background-color: #49483e; }
  background: #272822;
  color: #f8f8f2;
  .c {
    color: #75715e; }
  .err {
    color: #960050;
    background-color: #1e0010; }
  .k {
    color: #66d9ef; }
  .l {
    color: #ae81ff; }
  .n {
    color: #f8f8f2; }
  .o {
    color: #fb70a2; }
  .p {
    color: #f8f8f2; }
  .ch, .cm, .cp, .cpf, .c1, .cs {
    color: #75715e; }
  .gd {
    color: #fb70a2; }
  .ge {
    font-style: italic; }
  .gi {
    color: #a6e22e; }
  .gs {
    font-weight: bold; }
  .gu {
    color: #75715e; }
  .kc, .kd {
    color: #66d9ef; }
  .kn {
    color: #fb70a2; }
  .kp, .kr, .kt {
    color: #66d9ef; }
  .ld {
    color: #e6db74; }
  .m {
    color: #ae81ff; }
  .s {
    color: #e6db74; }
  .na {
    color: #a6e22e; }
  .nb {
    color: #f8f8f2; }
  .nc {
    color: #a6e22e; }
  .no {
    color: #66d9ef; }
  .nd {
    color: #a6e22e; }
  .ni {
    color: #f8f8f2; }
  .ne, .nf {
    color: #a6e22e; }
  .nl, .nn {
    color: #f8f8f2; }
  .nx {
    color: #a6e22e; }
  .py {
    color: #f8f8f2; }
  .nt {
    color: #fb70a2; }
  .nv {
    color: #f8f8f2; }
  .ow {
    color: #fb70a2; }
  .w {
    color: #f8f8f2; }
  .mb, .mf, .mh, .mi, .mo {
    color: #ae81ff; }
  .sa, .sb, .sc, .dl, .sd, .s2 {
    color: #e6db74; }
  .se {
    color: #ae81ff; }
  .sh, .si, .sx, .sr, .s1, .ss {
    color: #e6db74; }
  .bp {
    color: #f8f8f2; }
  .fm {
    color: #a6e22e; }
  .vc, .vg, .vi, .vm {
    color: #f8f8f2; }
  .il {
    color: #ae81ff; } }
