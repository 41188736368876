ul.grid-lg, ul.grid-sm {
  list-style: none;
  padding: 0;

  > li {
    margin-bottom: 25px; }

  a.link {
    @include transition;
    background: $cream url(../img/link.svg) no-repeat center center;
    border: 2px solid $black;
    bottom: -2px;
    height: 40px;
    position: absolute;
    right: -2px;
    width: 40px;
    &:hover {
      background-color: $teal_light; } }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    h2, h3 {
      flex: 2; }

    a {
      @include transition;
      &:hover {
        color: $orange_dark; } }

    i {
      font-size: 28px; } }

  .body {
    padding: 20px;
    p {
      a {
        color: $teal;
        text-decoration: none;
        &:hover {
          text-decoration: underline; } } } } }

ul.grid-lg {
  @media (min-width: 940px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px; }

  > li {
    border: 2px solid $black;
    box-shadow: 4px 4px 0 $black;
    position: relative;

    i {
      margin-top: 6px; }
    h2 {
      margin: 0; }

    .header {
      @include box-sizing;
      background: $black;
      min-height: 200px;
      padding-bottom: 50%;
      position: relative;
      > div {
        height: 100%;
        overflow: hidden;
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center; }
      .img_container {
        img {
          height: auto;
          object-fit: cover;
          width: 100%; } } } }

  li.contact {
    border-color: $teal;
    box-shadow: 4px 4px 0 $teal;
    text-align: center;

    .header {
      background: $teal_light url(../img/pixels.svg) no-repeat center bottom;
      > div {
        > div {
          flex: 2; } }
      .sip {
        background: url(../img/sip-of-code-icon.svg) no-repeat center 0;
        background-size: 100% auto;
        height: 90px;
        margin: 0 auto;
        width: 80px; }

      h4 {
        margin: 10px 0 0; } }

    p {
      margin-top: 0; }
    .mailbox {
      margin: -10px auto 10px; } } }

ul.tags {
  list-style: none;
  padding: 0;
  li {
    background: $teal_light;
    display: inline-block;
    font-family: 'IBM Plex Mono', monospace;
    font-size: 13px;
    font-weight: 600;
    margin: 0 10px 10px 0;
    padding: 6px 12px;
    text-transform: uppercase;
    &:nth-child(3n+2) {
      background-color: $orange; }
    &:nth-child(3n+3) {
      background-color: $gray; } } }


ul.grid-sm {

  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
    display: grid;
    gap: 25px; }

  @media (min-width: 1040px) {
    grid-template-columns: 1fr 1fr 1fr; }

  > li {
    border: 2px solid $black;
    box-shadow: 6px 6px 0 rgba($black, 0.2);
    position: relative;
    background: $black;
    color: $cream; }

  .flex {
    color: $black;
    background: $teal;
    padding:  10px 20px;
    a {
      color: $black; }
    h3 {
      margin: 0; }

    i {
      margin-top: 2px; } }

  .body {
    padding: 5px 20px 40px;
    font-family: 'IBM Plex Mono', monospace;
    p {
      font-size: 15px; } } }



