$cream: #fcfaf0;

$orange: #e79074;
$orange_dark: #d86448;

$teal: #74c1bf;
$teal_light: #a5d1d1;

$black: #383a37;
$gray: #c3c4c3;
$gray_line: #d7d8d7;
$gray_medium: #383a37;
