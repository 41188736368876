body.article {

  h1 {
    margin-bottom: 5px;
    &.invisible {
      color: $cream; }
    @media (min-width: 900px) {
      margin-right: 220px; } }

  .hidden {
    display: none; }

  blockquote {
    background: #edf6f6;
    border-left: 6px solid $teal_light;
    font-style: normal;
    font-size: 16px;
    padding: 20px;
    &.alt {
      background: #fae9e3;
      border-color: $orange;
      font-style: italic; }
    .author {
      text-align: right;
      margin-top: 10px; }
    p {
      margin: 0; } }

  section {
    position: relative;
    h2 {
      margin-top: 40px; }

    h3 {
      font-size: 19px;
      font-weight: 600; }

    h4 {
      font-size: 18px;
      font-weight: 600; }
    h5 {
      font-size: 17px;
      font-weight: 600; }
    h6 {
      font-size: 16px;
      font-weight: 600; }

    ol, ul {
      font-size: 16px;
      li {
        margin-bottom: 5px; } }
    ol {
      li::marker {
        font-weight: bold; } }
    ul {
      li::marker {
        color: $teal; } } }




  div[class*="language"] {
    &:before {
      background: url(../img/circles.svg) no-repeat left top;
      border: 2px solid $black;
      border-radius: 10px 10px 0 0;
      border-width: 2px 2px 0 2px;
      content: "";
      display: block;
      font-size: 14px;
      font-weight: bold;
      min-height: 32px;
      padding: 6px;
      text-align: center; } } }



#lang-switcher {
  @include box-sizing;
  // @include transition(box-shadow, 0.2s)
  overflow: hidden;
  padding: 20px;
  text-align: right;
  ul {
    list-style: none;
    margin: 0; }

  .wrapper {
    padding: 0;
    @media (min-width: 768px) {
      margin-left: 260px; }
    @media (min-width: 1340px) {
      margin-left: 316px; } }


  @media (min-width: 900px) {
    margin-top: 110px;
    position: absolute;
    right: -6px;
    top: 0;
    &.notice_present {
      margin-top: 166px; } }


  @media (max-width: 899px) {
    padding: 0; }


  .title {
    display: none; }


  &.fixed {
    width: 100%;
    background-color: rgba($cream, 0.94);
    box-shadow: 0 5px 5px rgba($black, 0.25);
    position: fixed;
    z-index: 2;
    top: -110px;
    left: -2px;
    right: auto;

    .title {
      display: block;
      float: left;
      font-size: 34px;
      font-weight: bold;
      margin-left: 45px;
      @media (min-width: 1340px) {
        margin-left: -10px; }
      @media (min-width: 1340px) {
        margin-left: 29px; } } }


  li {
    display: inline-block;
    margin: 0 0 0 5px;
    a {
      @include transition;
      background: no-repeat center center;
      border-radius: 3px;
      border: 2px solid $gray_line;
      display: inline-block;
      height: 40px;
      text-decoration: none;
      text-indent: 30000px;
      width: 40px;
      &:not(.active):hover {
        border-color: $teal;
        background-color: rgba($teal_light, 0.3); }
      &.active {
        background-color: rgba($teal_light, 0.5);
        border-color: $teal;
        pointer-events: none; }
      &.ruby {
        background-image: url(../img/icon-ruby.svg); }
      &.java {
        background-image: url(../img/icon-java.svg); }
      &.phyton {
        background-image: url(../img/icon-phyton.svg); }
      &.swift {
        background-image: url(../img/icon-swift.svg); } } } }





