body.about {
  section {
    background: url(../img/computer.svg) no-repeat right bottom;
    padding-bottom: 200px;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      background-position: center bottom; }
    h2 {
      background: $teal_light;
      color: $cream;
      display: inline-block;
      font-size: 22px;
      margin: 40px 0 10px;
      padding: 5px 16px;
      position: relative;
      &:before, &:after {
        background: $teal_light;
        content: "";
        content: "";
        height: 8px;
        position: absolute;
        width: 8px; }
      &:before {
        background: url(../img/2pixels.svg) no-repeat center center;
        height: 16px;
        left: -16px;
        top: -8px;
        width: 16px; }
      &:after {
        right: -8px;
        bottom: 0; }

      &#sobre-mí {
        &:before {
          background: $teal_light;
          bottom: -8px;
          height: 8px;
          left: -8px;
          top: auto;
          width: 8px; }
        &:after {
          background: url(../img/2pixels.svg) no-repeat center center;
          height: 16px;
          left: auto;
          top: -8px;
          right: -16px;
          width: 16px; } } } } }
