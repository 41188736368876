* {
  box-sizing: border-box;
  &::before, &::after {
    box-sizing: border-box; } }

.l-wrap {
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto; }

.l-article {
  &:after {
    display: table;
    clear: both;
    content: ""; } }
